.signature {
  display: block;
  margin: 100px auto;
  /*border: 2px solid black;*/
  max-width: 100%;
  height: 200px;
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  margin: auto;
  text-align: center;
  font-family: arial;
  transition: 0.5s;
  margin-bottom: 70px;
}
.signature:hover {
  box-shadow: 0 8px 16px 0 rgba(255, 0, 0, 0.2);
  transform: scale(1.05);
}
.box-signature {
  display: block;

  text-align: center;
}

